import React, { ReactElement, useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../layouts/Layout/Layout'
import './MissionVision.scss'
import ArticleLayout, {
  RecentArticleTypes,
} from '../../layouts/ArticleLayout/ArticleLayout'
import ABOUT_US_NAVIGATION from '../../data/about-us-navigation.json'
import Seo from '../../components/Seo/Seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'

type MissionVisionTypes = {
  title: string
  images: {
    description?: string
    featured?: boolean
    url: string
  }[]
  body: {
    type: string
    value: string
  }[]
}

type MissionVisionPropTypes = {
  data: {
    missionVisionData: {
      mission: MissionVisionTypes
      vision: MissionVisionTypes
    }
    recentArticles: {
      nodes: RecentArticleTypes[]
    }
  }
}

const MissionVision = ({
  data: {
    missionVisionData,
    recentArticles: { nodes: recentArticles },
  },
}: MissionVisionPropTypes): ReactElement => {
  const [bannerImage] = useState(missionVisionData.mission.images[0]?.url || '')

  return (
    <Layout>
      <Seo
        jsonData={{
          url: `${process.env.GATSBY_SITE_URL}/mission-and-vision/`,
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment, mission, vision, mission vision',
        }}
        title="Mission Vision"
      />
      <ArticleLayout
        title="About Us"
        asideNavItems={ABOUT_US_NAVIGATION}
        breadcrumbs={[
          {
            label: 'Mission Vision',
          },
        ]}
        recentArticles={recentArticles}
        contentClassName="mission-vision"
      >
        <>
          {!!bannerImage && (
            <LazyLoadImage
              src={bannerImage}
              alt="banner image"
              className="mission-vision-banner"
            />
          )}
          <div className="mission-vision-content-container">
            <h2 className="mission-vision-title">
              {missionVisionData.mission.title || 'Mission'}
            </h2>
            <div className="mission-vision-content">
              {!!missionVisionData.mission.body &&
                missionVisionData.mission.body.map((content) => (
                  <p key={content.value}>{content.value}</p>
                ))}
            </div>
            <h2 className="mission-vision-title">
              {missionVisionData.vision.title || 'Vision'}
            </h2>
            <div className="mission-vision-content">
              {!!missionVisionData.vision.body &&
                missionVisionData.vision.body.map((content) => (
                  <p key={content.value}>{content.value}</p>
                ))}
            </div>
          </div>
        </>
      </ArticleLayout>
    </Layout>
  )
}

export default MissionVision

export const pageQuery = graphql`
  query {
    missionVisionData: missionVision {
      ...MissionVisionFields
    }
    recentArticles: allNewsAndEvents(limit: 3) {
      nodes {
        ...NewsAndEventsCardFields
      }
    }
  }
`
